/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from '@material-ui/core/LinearProgress';

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';

const TechnologySection = props => {
  const { classes } = props;

  const { t, i18n } = useTranslation('experience', {i18n});

  return (
      <div id="technicalskills" className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h2 className={classes.title}>{t('technical.header')}</h2>
          </GridItem>
        </GridContainer><br />
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h3 className={classes.title} style={{ display: "flex", justifyContent: "left", marginTop:"0px", marginBottom: "20px" }}>Front-end:</h3>
              <GridContainer style={{ paddingLeft: "10px", paddingRight:"10px" }}>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "90px" }} src="https://www.eurelis.com/sites/default/files/styles/image_article/public/images/angular-5.png?itok=RX5HjDRE" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                    advanced
                    </div>
                  <LinearProgress variant="determinate" value="80" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "100px" }} src="https://fortimelp.fr/268-large_default/formation-html5-et-css3-3-jours-eligible-au-cpf-code-208980.jpg" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                    expert
                    </div>
                  <LinearProgress variant="determinate" value="85" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "80px" }} src="https://www.baptiste-donaux.fr/react-redux-concept/react-redux.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                  intermediate
                    </div>
                  <LinearProgress variant="determinate" value="65" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "90px", maxWidth: "160px" }} src="http://www.decatechlabs.com/wp-content/uploads/2017/11/ASP-NET-Core-Logo-1.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                  intermediate
                    </div>
                  <LinearProgress variant="determinate" value="70" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "100px", maxWidth: "160px" }} src="https://blog.ineat-conseil.fr/wp-content/uploads/2019/05/flutter-945x346.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                  intermediate
                    </div>
                  <LinearProgress variant="determinate" value="65" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "80px", maxWidth: "120px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Swift_logo_with_text.svg/1280px-Swift_logo_with_text.svg.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                    beginner
                    </div>
                  <LinearProgress color="secondary" variant="determinate" value="50" style={{ marginBottom: "10px" }} />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h3 className={classes.title} style={{ display: "flex", justifyContent: "left", marginTop:"0px", marginBottom: "20px" }}>Back-end:</h3>
              <GridContainer style={{ paddingLeft: "10px", paddingRight:"10px" }}>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "65px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/ee/.NET_Core_Logo.svg/220px-.NET_Core_Logo.svg.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                    expert
                    </div>
                  <LinearProgress variant="determinate" value="95" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "70px" }} src="https://blog.floydhub.com/content/images/2019/07/docker.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                    advanced (certified)
                    </div>
                  <LinearProgress variant="determinate" value="85" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "150px" }} src="https://i1.wp.com/www.msclouditpropodcast.com/wp-content/uploads/2019/01/Azure.png?fit=1140%2C1140&ssl=1" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                    intermediate (certified)
                    </div>
                  <LinearProgress variant="determinate" value="65" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "60px" }} src="https://cdn.worldvectorlogo.com/logos/microsoft-sql-server.svg" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                    advanced
                    </div>
                  <LinearProgress variant="determinate" value="85" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "60px" }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/1280px-Node.js_logo.svg.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                  intermediate
                    </div>
                    <LinearProgress variant="determinate" value="65" style={{ marginBottom: "10px" }} />
                </GridItem>
                <GridItem className="MuiPaper-elevation1" style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80px", marginTop: "10px" }}>
                    <img style={{ maxHeight: "80px", maxWidth: "130px" }} src="https://upload.wikimedia.org/wikipedia/fr/thumb/4/45/MongoDB-Logo.svg/1280px-MongoDB-Logo.svg.png" alt="" />
                  </div>
                  <div style={{ display: "flex", justifyContent: "left", color: "black", fontVariant: "small-caps" }}>
                  intermediate
                    </div>
                  <LinearProgress variant="determinate" value="50" style={{ marginBottom: "10px" }} />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }

TechnologySection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(TechnologySection);
