/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { container, title } from "assets/jss/material-kit-react.jsx";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    "@media (min-width: 10px)": {
      marginTop: "80px",
    },
    "@media (min-width: 768px)": {
      marginTop: "80px",
    },
    "@media (min-width: 992px)": {
      marginTop: "150px",
    },
    "@media (min-width: 1200px)": {
      marginTop: "150px",
    },    
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    "@media (min-width: 10px)": {
      margin: "5px 5px 0px",
    },
    "@media (min-width: 768px)": {
      margin: "15px 15px 0px",
    },
    "@media (min-width: 992px)": {
      margin: "30px 30px 0px",
    },
    "@media (min-width: 1200px)": {
      margin: "30px 30px 0px",
    },
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
};

export default landingPageStyle;
