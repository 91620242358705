/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import flagFr from "assets/img/flag_fr_32x32.png";
import flagUs from "assets/img/flag_us_32x32.png";
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';


const HeaderLinks = props => {
  const { classes } = props;

  const { t, i18n } = useTranslation('headerlinks');

  function handleClick() {

    var Scroll = require('react-scroll');
    var Element = Scroll.Element;
    var scroller = Scroll.scroller;

    scroller.scrollTo('career', {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: 'career',
      offset: 50, // Scrolls to element + 50 pixels down the page
    });   
  }  
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link className={classes.navLink} to="about" spy={true} smooth={true} duration={500}>
        {t('about')}
        </Link>        
      </ListItem>      <ListItem className={classes.listItem}>
      <Link className={classes.navLink} to="career" spy={true} smooth={true} duration={500}>
          Experience
        </Link>        
      </ListItem>
            <ListItem className={classes.listItem}>
            <Link className={classes.navLink} to="technicalskills" spy={true} smooth={true} duration={500}>
          {t('skills')}
        </Link>        

      </ListItem>
            <ListItem className={classes.listItem}>
            <Link className={classes.navLink} to="carousel" spy={true} smooth={true} duration={500}>
          Portfolio
        </Link>        

      </ListItem> 
      <ListItem className={classes.listItem}>
      <Link className={classes.navLink} to="contact" spy={true} smooth={true} duration={500}>
          Contact
        </Link>        
      </ListItem>       
      <ListItem className={classes.listItem}>
        <img src={flagFr} style={{cursor:'pointer', marginLeft:"10px", paddingTop:"7px"}} onClick={() => props.setLanguage('fr')} />
        <img src={flagUs} style={{cursor:'pointer', marginLeft:"10px", paddingTop:"7px"}} onClick={() => props.setLanguage('en')} />
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
