
import React from "react";
import PropTypes from "prop-types";
// nodejs library to set properties for components
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import homePageStyle from "assets/jss/material-kit-react/views/homePage.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';


const HeadlineSection = props => {
  const { classes } = props;

  const { t } = useTranslation('homepage');

  return (
    <div className={classes.container}>
    <h1 className={classes.title}>Stéphane Bance</h1><br />
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h4><b>{t('headline')}</b></h4>
      </GridItem>
    </GridContainer>
  </div>
  );
}

HeadlineSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(homePageStyle)(HeadlineSection);
