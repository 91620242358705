/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { cardTitle, title } from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

const teamStyle = {
  section: {    
    "@media (min-width: 10px)": {
      padding: "0 0 0 0",
    },
    "@media (min-width: 768px)": {
      padding: "30px 0 0 0",
    },
    "@media (min-width: 992px)": {
      padding: "70px 0 0 0",
    },
    "@media (min-width: 1200px)": {
      padding: "70px 0 0 0",
    },    
    textAlign: "center"
  },
  title: {
    ...title,
    "@media (min-width: 10px)": {
      padding: "0 0 0 0",
    },
    "@media (min-width: 768px)": {
      marginBottom: "20px",
    },
    "@media (min-width: 992px)": {
      marginBottom: "50px",
    },
    "@media (min-width: 1200px)": {
      marginBottom: "50px",
    },           
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#3c4858",
    textAlign:"justify",
    fontSize:"16px"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  margin5: {
    margin: "5px"
  },
  highlight:{
    color: "#f8b600",
    fontWeight: 500
  },
  h4medium:{
    fontSize:"15px",
    lineHeight:"27px"
  },
};

export default teamStyle;
