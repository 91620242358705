/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { title } from "../../../../../assets/jss/material-kit-react.jsx";

const workStyle = {
  section: {
    "@media (min-width: 10px)": {
      padding: "0 0 0 0",
    },
    "@media (min-width: 768px)": {
      padding: "30px 0 0 0",
    },
    "@media (min-width: 992px)": {
      padding: "70px 0 0 0",
    },
    "@media (min-width: 1200px)": {
      padding: "70px 0 0 0",
    },       
  },
  title: {
    ...title,
    "@media (min-width: 10px)": {
      padding: "0 0 0 0",
    },
    "@media (min-width: 768px)": {
      marginBottom: "20px",
    },
    "@media (min-width: 992px)": {
      marginBottom: "50px",
    },
    "@media (min-width: 1200px)": {
      marginBottom: "50px",
    },           
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
  },
  description: {
    color: "#999",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  }
};

export default workStyle;
