/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';

const IntroductionSection = props => {
  const { classes } = props;

  const { t, i18n } = useTranslation('introduction', {i18n});

  return (
    <div id="about" className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{t('title')}</h2>
        </GridItem>
      </GridContainer>

      <div>
      <GridContainer style={{color:'#5f5d5d', textAlign:"justify"}}>
        <GridItem xs={12} sm={12} md={12}>
        <h5>
        <object dangerouslySetInnerHTML={{ __html: t('headline', { 0: '<br/>'})}} />
</h5>

        </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <h4 className={classes.h4medium}>
            <object dangerouslySetInnerHTML={{ __html: t('column_left', { 0: '<b class="' + classes.highlight + '">' + t('responsible') + '</b>', 1: '<br/>',2: '<b class="' + classes.highlight + '">' + t('technology') + '</b>'})}} />
              </h4>

            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h4 className={classes.h4medium}>
              <object dangerouslySetInnerHTML={{ __html: t('column_right', { 0: '<b class="' + classes.highlight + '">' + t('customer_oriented') + '</b>', 1: '<br/>',2: '<b class="' + classes.highlight + '">' + t('digital_expert') + '</b>'})}} />
              </h4>
              <br/>
              <Button
                color="danger"
                size="lg"
                href="CV_stephane_bance.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-address-card" />
                {t('download_cv')}
              </Button>                
            </GridItem>
          </GridContainer>
      </div>
    </div>
  );
}

IntroductionSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(IntroductionSection);
