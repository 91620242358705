/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from '@material-ui/core/LinearProgress';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';
import pfBackend from "assets/portfolio/cw_backend.jpg";
import pfDashboard from "assets/portfolio/cw_dashboard.jpg";
import pfWorkflow from "assets/portfolio/cw_workflow.png";
import pfEditorDash from "assets/portfolio/cw_editor_dash.jpg";
import pfEditor from "assets/portfolio/cw_demo_02.jpg";
import pfEditor2 from "assets/portfolio/cw_editor.jpg";
import pfWorkerMobile from "assets/portfolio/cw_mobile.jpg";
import pfScorecardv1 from "assets/portfolio/scorecard_v1.jpg";
import pfProjectPlanning from "assets/portfolio/project_planning.jpg";
import pfScorecardApp from "assets/portfolio/scorecard_V1_2.jpg";
import pfevents from "assets/portfolio/cw_events.jpg";
import pfbusinessworkflow from "assets/portfolio/cw_business_workflow.jpg";
import pfcwroadmap from "assets/portfolio/cw_roadmap.jpg";
import pfmockups from "assets/portfolio/mockups.jpg";
import pfriskbasedtesting from "assets/portfolio/risk_based_testing.jpg";

const PortfolioSection = props => {
  const { classes } = props;

  const { t, i18n } = useTranslation('homepage', {i18n});

  return (
      <div id="carousel" className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h2 className={classes.title}>Portfolio</h2>
          </GridItem>
        </GridContainer><br />
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.title} style={{ display: "flex", justifyContent: "left", marginTop:"0px", marginBottom: "20px" }}>{t('project_management')}</h3>
               <Carousel autoPlay infiniteLoop>
                    <div>
                      <img src={pfProjectPlanning} />
                      <p className="legend">{t('project_planning')}</p>
                    </div>
                    <div>
                    <img src={pfcwroadmap} />
                    <p className="legend">Roadmap</p>
                    </div>
                    <div>
                    <img src={pfbusinessworkflow} />
                    <p className="legend">{t('technical_workflow')}</p>
                    </div>
                    <div>
                    <img src={pfevents} />
                    <p className="legend">{t('company_events_overview')}</p>
                    </div>
                    <div>
                    <img src={pfScorecardApp} />
                    <p className="legend">{t('custom_kpi_dashboard')}</p>
                    </div>
                    <div>
                    <img src={pfScorecardv1} />
                    <p className="legend">{t('custom_defects_kanban')}</p>
                    </div>
                    <div>
                    <img src={pfriskbasedtesting} />
                    <p className="legend">{t('risk_based_testing')}</p>
                    </div>
                                        <div>
                    <img src={pfmockups} />
                    <p className="legend">{t('mockups_creation')}</p>
                    </div>
                    <div>
                    <img src={pfWorkflow} />
                    <p className="legend">{t('business_presentation')}</p>
                    </div>
                </Carousel>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <h3 className={classes.title} style={{ display: "flex", justifyContent: "left", marginTop:"0px", marginBottom: "20px" }}>{t('development')}</h3>
               <Carousel autoPlay infiniteLoop>
                    <div>
                    <img src={pfBackend} />
                    <p className="legend">{t('digital_asset_management')}</p>
                    </div>
                    <div>
                    <img src={pfDashboard} />
                    <p className="legend">{t('field_worker')}</p>
                    </div>
                    <div>
                    <img src={pfEditorDash} />
                    <p className="legend">{t('cted_worker_editor')}</p>
                    </div>
                    <div>
                    <img src={pfEditor} />
                    <p className="legend">{t('cted_worker_editor')}</p>
                    </div>
                    <div>
                    <img src={pfEditor2} />
                    <p className="legend">{t('cted_worker_editor')}</p>
                    </div>
                    <div>
                    <img src={pfWorkerMobile} />
                    <p className="legend">{t('cted_worker_mobile')}</p>
                    </div>
                </Carousel>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }

  PortfolioSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(PortfolioSection);
