/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, Suspense, useEffect} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import homePageStyle from "assets/jss/material-kit-react/views/homePage.jsx";

// Sections for this page
import ExperienceSection from "./Sections/ExperienceSection.jsx";
import ContactSection from "./Sections/ContactSection.jsx";
import IntroductionSection from "./Sections/IntroductionSection.jsx";
import TechnologySection from "./Sections/TechnologySection.jsx";
import HeadlineSection from "./Sections/HeadlineSection.jsx";
import PortFolio from "./Sections/PortfolioSection.jsx";
import i18n from 'i18n.js';


const dashboardRoutes = [];

const HomePage = props => {

    const { classes, ...rest } = props;
  
    const [language, setLanguage] = useState('fr');

    useEffect(() => {
      console.log(language);
      i18n.changeLanguage(language);


    }, language);

    return (
      <Suspense fallback="loading">
      <div style={{ backgroundColor: '#646486' }} id="about">
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="Stéphane Bance Portfolio"
          rightLinks={<HeaderLinks setLanguage={setLanguage} />}
          fixed
          changeColorOnScroll={{
            height: 40,
            color: "white"
          }}
          {...rest}
        />

        <HeadlineSection />

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <IntroductionSection />
            <ExperienceSection language={language} />
            <TechnologySection />
            <PortFolio />
            <ContactSection language={language} />
          </div>
        </div>
        <Footer />
      </div></Suspense>
    );
}

HomePage.propTypes = {
  classes: PropTypes.object
};



export default withStyles(homePageStyle)(HomePage);
