/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class WorkSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Contact me</h2>
            <h4 className={classes.description}>
              Divide details about your product or agency work into parts. Write
              a few lines about each one and contact us about any further
              collaboration. We will responde get back to you in a couple of
              hours.
            </h4><br/><br/>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

WorkSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(workStyle)(WorkSection);*/


import React from "react";
import PropTypes from "prop-types";
// nodejs library to set properties for components
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import workStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n.js';


const WorkSection = props => {
  const { classes } = props;

  const { t, i18n } = useTranslation('contact', {i18n});

  return (
    <div id="contact" className={classes.section}>
    <GridContainer justify="center">
      <GridItem cs={12} sm={12} md={8}>
  <h2 className={classes.title}>{t('header')}</h2>
        <h4 className={classes.description}>
          <b>Email:</b> <a href="mailto:stephane.bance@gmail.com" target="_blank">stephane.bance@gmail.com</a><br/>
          <b>LinkedIn:</b> <a href="https://www.linkedin.com/in/stebance/" target="_blank">https://www.linkedin.com/in/stebance/</a><br/>
          <b><a href="javascript:cookieManagement.show()">Gestion des cookies</a></b>
        </h4><br/><br/>
      </GridItem>
    </GridContainer>
  </div>
  );
}

WorkSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(workStyle)(WorkSection);
