/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

// @material-ui/icons
import DeveloperIco from "@material-ui/icons/DeveloperMode";
import ProjectManagerIco from "@material-ui/icons/Assignment";
import QAIco from "@material-ui/icons/CheckCircleOutline";
import ProgramManagerIco from "@material-ui/icons/DeviceHub";
// core components
import InfoArea from "components/InfoArea/InfoArea.jsx";

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n.js';

import logoNokia from "assets/logo/logo-Nokia.png";
import logoAirbus from "assets/logo/logo-airbus.png";
import logoUniversal from "assets/logo/logo-universal.png";
import logoAccenture from "assets/logo/logo-accenture.png";
import logoSony from "assets/logo/logo-sony.png";
import logoThales from "assets/logo/logo-thales.png";
import logoNMitsubishi from "assets/logo/logo-mitsubishi.png";
import logoAstellas from "assets/logo/logo-astellas.png";



const ProductSection = props => {

  const { classes } = props;

  const { t, i18n } = useTranslation('experience', {i18n});

    return (
      <div id="career" className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <h2 className={classes.title}>{t('experience.experience')}</h2>
            <h5 className={classes.description} style={{ textAlign: "justify" }}>
            <object dangerouslySetInnerHTML={{ __html: t('experience.description', { 0: '<b class="' + classes.highlight + '">Universal Music Group</b>', 1: '<b class="' + classes.highlight + '">' + t('experience.digiplug') + '</b>', 2: '<b class="' + classes.highlight + '">Accenture</b>', 3: '<br/>'})}} />
            </h5>
          </GridItem>
        </GridContainer><br/>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <div style={{
                height: "100%",
                backgroundColor: "#fbfbfb", padding: "15px 15px 15px 15px"
              }}>
                <InfoArea
                  title={t('intro.developer_title')}
                  iconText={"14 " + t('intro.years')}
                  icon={DeveloperIco}
                  iconColor="warning"
                  vertical
                />
                <div style={{ color: 'black', textAlign: 'left', margin: '0 auto', maxWidth: '340px', fontSize: '14px', lineHeight: '27px' }}>
                <object dangerouslySetInnerHTML={{ __html: t('intro.developer_desc', {0: '<br/>'})}}/>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <div style={{
                height: "100%",
                backgroundColor: "#fbfbfb", padding: "15px 15px 15px 15px"
              }}>
                <InfoArea
                  title={t('intro.program_managment_title')}
                  iconText={"4 " + t('intro.years')}
                  icon={ProgramManagerIco}
                  iconColor="info"
                  vertical
                />
                <div style={{ color: 'black', textAlign: 'left', margin: '0 auto', maxWidth: '340px', fontSize: '14px', lineHeight: '27px' }}>
                <object dangerouslySetInnerHTML={{ __html: t('intro.program_managment_desc', {0: '<br/>'})}}/>
              </div></div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <div style={{
                height: "100%",
                backgroundColor: "#fbfbfb", padding: "15px 15px 15px 15px"
              }}>
                <InfoArea
                  title={t('intro.project_managment_title')}
                  iconText={"6 " + t('intro.years')}
                  icon={ProjectManagerIco}
                  iconColor="info"
                  vertical
                />
                <div style={{ color: 'black', textAlign: 'left', margin: '0 auto', maxWidth: '340px', fontSize: '14px', lineHeight: '27px' }}>
                <object dangerouslySetInnerHTML={{ __html: t('intro.project_managment_desc', {0: '<br/>'})}}/>
              </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <div style={{
                height: "100%",
                backgroundColor: "#fbfbfb", padding: "15px 15px 15px 15px"
              }}>
                <InfoArea
                  title={t('intro.test_managment_title')}
                  iconText={"3 " + t('intro.years')}
                  icon={ProjectManagerIco}
                  iconColor="info"
                  vertical
                />
                <div style={{ color: 'black', textAlign: 'left', margin: '0 auto', maxWidth: '340px', fontSize: '14px', lineHeight: '27px' }}>
                <object dangerouslySetInnerHTML={{ __html: t('intro.test_managment_desc', {0: '<br/>'})}}/>
              </div></div>
            </GridItem>
          </GridContainer>
          <br /><br />
          <GridContainer style={{ textAlign: "left" }}>
            <GridItem xs={12} sm={12} md={12}>
              <h3 className={classes.title}>{t('client.header')}</h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "120px", maxHeight: "80px" }} src={logoUniversal} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "120px", maxHeight: "80px" }} src={logoAccenture} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "120px", maxHeight: "80px" }} src={logoNokia} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "15px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "130px", maxHeight: "80px" }} src={logoSony} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "120px", maxHeight: "80px" }} src={logoAirbus} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "120px", maxHeight: "80px" }} src={logoThales} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "15px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "120px", maxHeight: "80px" }} src={logoNMitsubishi} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card style={{ marginBottom: "20px" }}>
                    <CardContent style={{ paddingBottom: "10px", display: "flex", alignItems: "center", justifyContent: "center", height: "100px" }}>
                      <img style={{ maxWidth: "120px", maxHeight: "80px" }} src={logoAstellas} alt="" />
                    </CardContent>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
            <Hidden only={['xs', 'sm']}>
              <GridItem xs={12} sm={12} md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <GridContainer>
                  <GridItem>
                    <h1 className={classes.title}>{"14 " + t('intro.years')}</h1>
                    <h2 className={classes.title}>{t('client.xp_working')}</h2>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </Hidden>
          </GridContainer>
        </div>
      </div>
    );
}

ProductSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(ProductSection);
